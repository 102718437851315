import Cookies from 'js-cookie';
import { useEffect, useRef } from 'react';
import { optOutOfTracking, optIntoTracking } from 'src/api/LogRocketApi';
import useAuthentication from 'src/hooks/useAuthentication';
/**
 *  Log rocket component to check for GDPR/CCPA cookie and calls log rocket api to opt
 *  in and out of tracking based on the value of the cookie
 *
 *  opting in and out will delete all records we have under that user, or remove the user
 *  from the 'do-not-track' array they have stored.
 *
 *  Connects to (and dependent on) the CookieConsent script, which inserts/updates the teconsent div on the app's page
 *
 * @returns nothing - render this somewhere under the auth0Provider and it'll do it's thing
 */
const LogRocketOptOutChecker = () => {
    const { user } = useAuthentication();
    const cookieRef = useRef(Cookies.get('notice_gdpr_prefs') || ''); // need ref to let observer see updated value (stale closure issue otherwise)
    useEffect(() => {
        // Run initial cookie load/check on component mount
        //TODO: evaluate load on logrocket api & cost -- should we throttle this 
        checkAndUpdateUserPrefs(cookieRef.current);
        // Set up a MutationObserver to watch for changes in the teconsent div's consent prop (indicates a cookie update from our prefs modal)
        const targetNode = document.getElementById('teconsent');
        if (targetNode) {
            const observer = new MutationObserver(() => {
                console.log('in observer');
                handleCookieChange();
            });
            observer.observe(targetNode, { attributes: true });
            return () => {
                observer.disconnect(); // Cleanup on unmount
            };
        }
        else {
            console.log('no target node something broke');
        }
        // TODO: option to poll instead, to be CERTAIN we didn't miss any cookie changes, disconnect from this one teconsent div only
        // const intervalId = setInterval(handleCookieChange, 1000);
        // return () => clearInterval(intervalId);
    }, []);
    /** GDPR/CCPA privacy check
     * Checks the value of the passed in cookie and calls the correct logrocket api to opt the user in or out of tracking
     * @param gdprPrefs string value of the notice_gdpr_prefs cookie
     */
    const checkAndUpdateUserPrefs = (gdprPrefs) => {
        console.log(`checking and updating prefs for [${user?.userName}] - [${user?.userId}]`, gdprPrefs);
        if (user?.userName) {
            switch (gdprPrefs) {
                case '0:':
                    console.log('user has opted out of tracking! - call the LogSmith!');
                    optOutOfTracking(user?.userName);
                    break;
                case '0,1:':
                case '0,1,2:':
                    console.log('user has opted into tracking! - call the LogSmith!');
                    optIntoTracking(user?.userName);
                    break;
                default: //No cookie or didn't match
                    console.log('implied consent to track!');
                    break;
            }
        }
    };
    // let timeout: string | number | NodeJS.Timeout | undefined;
    /**
     *  called when the observer notices a change to the consent property on the teconsent div
     *  triggers an update to preferences if the users cookie changed
     */
    const handleCookieChange = () => {
        console.log('handle cookie change');
        const newCookieValue = Cookies.get('notice_gdpr_prefs') || '';
        console.log('existing cookie ref', cookieRef.current);
        console.log('check current value on browser', newCookieValue);
        if (newCookieValue !== cookieRef.current) {
            console.log('handle cookie change - setting value');
            cookieRef.current = newCookieValue;
            checkAndUpdateUserPrefs(newCookieValue);
        }
    };
    // functional component only - nothing to render
    return null;
};
export default LogRocketOptOutChecker;
